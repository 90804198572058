import React from "react";

import Image from "./Image";

import LinkButton from "./LinkButton";

const HeroAbout = ({
  title,
  aboutPanelText,
  aboutPanelLink,
  aboutPanelImage,
  aboutPanelImageCaption,
}) => {
  return (
    <div className="2xl:container relative mt-28 lg:mt-36 md:flex justify-end">
      <Image
        image={aboutPanelImage}
        alt={aboutPanelImage.altText}
        className={`min-h-[350px] md:min-h-[800px] screen-minus-nav block object-cover w-full md:max-w-[75%]`}
      />
      <p className="text-sm text-white absolute transform translate-y-[-30px] md:translate-y-[30px] md:bottom-8 right-12 md:right-24">
        {aboutPanelImageCaption}
      </p>
      <div className="md:absolute bottom-0 left-0 z-10 md:max-w-[480px] px-12 py-16 md:py-12 md:px-24 text-white bg-gradient-to-b from-cerulean-500 to-cerulean-700">
        <h2 className="text-5xl leading-tight font-bold mb-4 uppercase text-white md:text-indent--4">
          {title}
        </h2>
        <div
          className="mb-8"
          dangerouslySetInnerHTML={{ __html: aboutPanelText }}
        ></div>
        <LinkButton link={aboutPanelLink.url} label={aboutPanelLink.title} />
      </div>
    </div>
  );
};

export default HeroAbout;
