import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import Testimonial from "./Testimonial";

// Import Swiper styles
import "swiper/swiper.scss";

const SliderTestimonial = ({ slides }) => {
  SwiperCore.use([Autoplay]);

  return (
    <Swiper
      className="w-full bg-darkblue-100"
      spaceBetween={0}
      slidesPerView={1}
      loop={true}
      autoplay={{ delay: 12000 }}
      onSlideChange={() => {}}
      onSwiper={(swiper) => {}}
    >
      {slides.map((slide, i) => {
        return (
          <SwiperSlide key={i} className="">
            <Testimonial key={slide.id} data={slide} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default SliderTestimonial;
