import React from "react";

const Testimonial = ({ data }) => {
  return (
    <div className="container text-darkblue relative mx-auto px-12 py-20 pt-32 md:px-0 md:py-24 max-w-full md:max-w-[600px]">
      <svg
        className="absolute top-16 md:top-20 md:-left-20"
        width="50"
        height="40"
        viewBox="0 0 50 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.44 29.6085C22.44 23.6299 18.904 19.7865 12.784 19.7865C11.56 19.7865 10.608 20.0712 9.928 20.4982C10.2 13.9502 15.912 9.53736 21.08 8.68327V0C12.512 0.85409 0 7.25979 0 26.3345C0 33.879 4.624 40 11.832 40C18.224 40 22.44 35.1601 22.44 29.6085ZM49.232 29.6085C49.232 23.6299 45.696 19.7865 39.576 19.7865C38.352 19.7865 37.4 20.0712 36.72 20.4982C36.992 13.9502 42.704 9.53736 47.872 8.68327V0C39.304 0.85409 26.792 7.25979 26.792 26.3345C26.792 33.879 31.416 40 38.624 40C45.016 40 49.232 35.1601 49.232 29.6085Z"
          fill="#009ADF"
        />
      </svg>

      <div
        className="testimonial-text"
        dangerouslySetInnerHTML={{ __html: data.testimonials.testimonial }}
      ></div>
      <p>
        <strong>{data.testimonials.testimonialName}</strong>
      </p>
      <p>{data.testimonials.testimonialCompany}</p>

      <svg
        className="absolute bottom-24 md:bottom-36 right-12 md:-right-16 transform rotate-180"
        width="50"
        height="40"
        viewBox="0 0 50 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.44 29.6085C22.44 23.6299 18.904 19.7865 12.784 19.7865C11.56 19.7865 10.608 20.0712 9.928 20.4982C10.2 13.9502 15.912 9.53736 21.08 8.68327V0C12.512 0.85409 0 7.25979 0 26.3345C0 33.879 4.624 40 11.832 40C18.224 40 22.44 35.1601 22.44 29.6085ZM49.232 29.6085C49.232 23.6299 45.696 19.7865 39.576 19.7865C38.352 19.7865 37.4 20.0712 36.72 20.4982C36.992 13.9502 42.704 9.53736 47.872 8.68327V0C39.304 0.85409 26.792 7.25979 26.792 26.3345C26.792 33.879 31.416 40 38.624 40C45.016 40 49.232 35.1601 49.232 29.6085Z"
          fill="#009ADF"
        />
      </svg>
    </div>
  );
};

export default Testimonial;
