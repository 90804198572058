import React from "react";
import { graphql } from "gatsby";
import SliderTestimonial from "./SliderTestimonial";

export const fragment = graphql`
  fragment TestimonialContent on WP_Testimonial {
    id
    title
    testimonials {
      testimonial
      testimonialCompany
      testimonialName
    }
  }
`;

const Testimonials = ({ testimonials }) => {
  return (
    <div>
      <SliderTestimonial slides={testimonials} />
    </div>
  );
};

export default Testimonials;
