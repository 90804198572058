import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import HeroAbout from "../components/HeroAbout";
import Testimonials from "../components/Testimonials";

export const pageQuery = graphql`
  {
    wp {
      page(id: "/about/", idType: URI) {
        id
        title
        aboutPage {
          aboutPageHero {
            aboutPanelText
            aboutPanelLink {
              target
              title
              url
            }
            aboutPanelImage {
              ...MediaItem
            }
            aboutPanelImageCaption
          }
          aboutTestimonialPanel {
            ...TestimonialContent
          }
        }
      }
    }
  }
`;

const about = ({ data }) => {
  const page = data.wp.page;
  const fields = page.aboutPage;

  return (
    <Layout title={page.title}>
      <HeroAbout title={page.title} {...fields.aboutPageHero} />
      <Testimonials testimonials={fields.aboutTestimonialPanel} />
      {/* Testimonials */}
    </Layout>
  );
};

export default about;
